import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import { LINKS } from "../constants/links";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import AppButton from "../components/common/button";
import { isCameraError } from "../utils/cookies";
import EmptyBillSVG from "../icons/empty_bill.svg";
import { AppContainer } from "../components/common/appContainer";

const StyledButtonWrapper = styled.div`
	margin-bottom: 40px;
	text-align: center;
`;

const StyledNotFoundImgWrapper = styled.div`
	padding: 45px 0 60px;
	text-align: center;
`;

const BillError = () => {
	const { t } = useTranslation();

	return (
		<>
			<SEO title={t("billError.title")} />
			<AppContainer>
				<AppPageHeader>{t("billError.header")}</AppPageHeader>
				<AppLeadingText>{t("billError.description")}</AppLeadingText>
				<StyledNotFoundImgWrapper>
					<EmptyBillSVG />
				</StyledNotFoundImgWrapper>
				<StyledButtonWrapper>
					<AppButton as={Link} to={isCameraError() ? LINKS.ENTER_CODE : LINKS.SCAN_QR}>
						{t("common.tryAgain")}
					</AppButton>
				</StyledButtonWrapper>
			</AppContainer>
		</>
	);
};

export default BillError;
